import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import IframeLayout from '../components/iframe-layout/iframe-layout';
import IframePromo from '../components/iframe-promo/iframe-promo';

const SSBusinessPromo = ({ data }) => {
  // Pull out the relevant content from the data.
  // Make sure at least one result is returned before rendering the layout.
  const content = data.allTaxonomyTermPromotion.edges[0].node.relationships
    ? data.allTaxonomyTermPromotion.edges[0].node.relationships
      .field_promoted_content[0]
    : null;

  return (
    <React.Fragment>
      {content && (
        <IframeLayout>
          <IframePromo content={content} />
        </IframeLayout>
      )}
    </React.Fragment>
  );
};

SSBusinessPromo.propTypes = {
  data: PropTypes.object
};

export default SSBusinessPromo;

export const query = graphql`
  query ssBusiness {
    allTaxonomyTermPromotion(filter: { drupal_id: { eq: "debf236c-4911-4b13-b3a3-b8131b3f42c2" } }) {
      edges {
        node {
          name
          relationships {
            field_promoted_content {
              ...IframeEvent
              ...IframeNews
              ...IframePlace
            }
          }
        }
      }
    }
  }
`;
